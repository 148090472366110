import { Injectable } from '@angular/core'
import { CorrespondenceTagReadList, MailIMAPAccountRead, MailService, OrdersService } from '@app/generated'
import { Observable, of, Subject, tap } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AclService } from '@app/acl/services/acl.service'
import { Permission } from '@app/acl/enums/permissions.enum'

@Injectable({
	providedIn: 'root',
})
export class MailboxInnerService {
	selectedEmailAccount?: MailIMAPAccountRead
	selectedEmailAccountChange$: Subject<void> = new Subject()
	private correspondenceTags?: CorrespondenceTagReadList

	constructor(
		private mailService: MailService,
		private ordersService: OrdersService,
		private aclService: AclService,
	) {}

	/**
	 * Sets the selected email address.
	 *
	 * @return {Observable<undefined>} An observable that emits undefined when the selected email address is set successfully.
	 */
	fetchSelectedAccount(force?: boolean): Observable<undefined> {
		if (!force && this.selectedEmailAccount) {
			return of(undefined)
		}

		return this.mailService
			.getImapAccounts(
				true,
				undefined,
				undefined,
				undefined,
				undefined,
				'desc',
				this.aclService.hasPermission([Permission.ADMIN]),
			)
			.pipe(
				switchMap(addresses => {
					this.selectedEmailAccount = addresses.items[0]
					return of(undefined)
				}),
			)
	}

	/**
	 * Sets the selected email account.
	 *
	 * @param {MailIMAPAccountRead} account - The email account to be set as selected.
	 * @return {void}
	 */
	setSelectedAccount(account?: MailIMAPAccountRead): void {
		this.selectedEmailAccount = account
		this.selectedEmailAccountChange$.next()
	}

	/**
	 * Retrieves the CorrespondenceTagReadList from the backend.
	 * If the correspondenceTags have already been fetched and stored in `this.correspondenceTags`, it returns an Observable of the stored CorrespondenceTagReadList.
	 * Otherwise, it makes a request to the ordersService to retrieve the CorrespondenceTagReadList from the backend, stores it in `this.correspondenceTags` and then returns an Observable of the retrieved CorrespondenceTagReadList.
	 *
	 * @returns {Observable<CorrespondenceTagReadList>} - An Observable of the CorrespondenceTagReadList retrieved from the backend.
	 */
	fetchCorrespondenceTags(): Observable<CorrespondenceTagReadList> {
		if (this.correspondenceTags?.items?.length) {
			return of(this.correspondenceTags)
		}
		return this.ordersService.getCorrespondeceTags(0, 100).pipe(tap(tags => (this.correspondenceTags = tags)))
	}
}
